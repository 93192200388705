import { AxiosError, isAxiosError } from 'axios'
import { Forbidden } from '@type/API/generated/models'

export enum ErrorMessages {
  AUTHENTICATION_ERROR = 'We are sorry, something went wrong. Please contact Altana support for assistance.',
  COUNTRY_OF_DESTINATION_ERROR = 'Country of destination is required.',
  VALUE_ERROR = 'Value is not a valid dollar amount.',
  HS_CODE_EMPTY_ERROR = 'Please enter an HS code.',
  INVALID_HS_CODE_ERROR = 'Please enter a valid HS code.',
  PREDICTION_SELECTION_ERROR = 'Please select a classification.',
  PRODUCT_DESCRIPTION_ERROR = 'Product description is required.',
}

export const translateAxiosErrorMessage = <T extends { message?: string }>(error?: T) => {
  if (isAxiosError(error)) {
    return error.response?.data?.message || error?.message
  }
}

export const translateErrorMessage = <T extends { message?: string }>(error?: T) =>
  translateAxiosErrorMessage(error) || error?.message || 'Unknown error'

export const getErrorCode = <T extends { message?: string }>(error?: T) => {
  if (isAxiosError(error)) {
    return error.request?.status || error.response?.status
  }
}
export const isForbidden = (error?: Error): error is AxiosError<Forbidden> =>
  isAxiosError(error) && error.response?.status === 403
