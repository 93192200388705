import { useGetOwnedExplorationsInternal } from '@type/API/generated/internal-v3-explorations/internal-v3-explorations'
import { CatalogFileMetadata, FileMetadata } from '@type/API/generated/models'
import { orderBy } from 'lodash'
import { useMemo } from 'react'
import { translateErrorMessage } from '@utils/ErrorMessages'
import { Spinner } from '@components/ui/Spinner'
import { isFeatureEnabled } from '@components/Feature'
import { useListCatalogFileMetadatas } from '@type/API/generated/internal-v3-catalog-files/internal-v3-catalog-files'
import { sprinkles } from '@styles/sprinkles.css'
import { useIsUserAuthorizedForCapabilities } from '@hooks/useIsUserAuthorizedForResources'
import { CatalogFileItem, ExplorationFileItem, FileList } from '@components/File'

const isCatalogFile = (file: FileMetadata): file is CatalogFileMetadata => file.file_type === 'CATALOG'

const isExplorationFile = (file: FileMetadata): file is FileMetadata => file.file_type === 'EXPLORATION'

export const RecentFiles = () => {
  const isAuthorizedForCatalog = useIsUserAuthorizedForCapabilities(['EDIT_CATALOG'])
  const {
    data: explorationsData,
    error: explorationsError,
    isInitialLoading: isExplorationsLoading,
  } = useGetOwnedExplorationsInternal()
  const {
    data: catalogFilesData,
    error: catalogFilesError,
    isInitialLoading: isCatalogFilesLoading,
  } = useListCatalogFileMetadatas(
    { created_by_authed_user_only: true },
    { query: { enabled: !isFeatureEnabled('disable_vc_ui') && isAuthorizedForCatalog } },
  )
  const sortedList = useMemo(() => {
    if (!explorationsData?.explorations && !catalogFilesData) return []
    const _explorationsData = explorationsData?.explorations
      ? explorationsData.explorations.map(file => ({
          created_at_utc_ms: file.created_at_utc_ms,
          updated_at_utc_ms: file.versions?.[file.versions.length - 1]?.created_at_utc_ms,
          file_type: 'EXPLORATION' as FileMetadata['file_type'],
          name: file.name ?? 'Unnamed Exploration',
          id: file.id ? String(file.id) : undefined,
        }))
      : []
    const _catalogFilesData = catalogFilesData ?? []
    const data = [..._explorationsData, ..._catalogFilesData]
    return orderBy(data, 'updated_at_utc_ms', 'desc').slice(0, 10)
  }, [explorationsData, catalogFilesData])
  const isLoading = isCatalogFilesLoading || isExplorationsLoading

  return (
    <>
      {catalogFilesError && (
        <div className={ErrorMessageContainer}>
          <b>Something went wrong: </b>
          <span>
            An error occurred while loading the saved Catalog workspaces: {translateErrorMessage(catalogFilesError)}
          </span>
        </div>
      )}
      {explorationsError && (
        <div className={ErrorMessageContainer}>
          <b>Something went wrong: </b>
          <span>
            An error occurred while loading your saved Explorations: {translateErrorMessage(explorationsError)}
          </span>
        </div>
      )}

      {isLoading ? (
        <Spinner />
      ) : (
        <FileList>
          {sortedList.map(file => {
            if (isExplorationFile(file)) {
              return <ExplorationFileItem file={file} key={file.id} />
            }

            if (isCatalogFile(file)) {
              return <CatalogFileItem file={file} key={file.id} />
            }

            return null
          })}
        </FileList>
      )}
    </>
  )
}

const ErrorMessageContainer = sprinkles({
  padding: 32,
  marginBottom: 32,
  border: 1,
})
