/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Altana Atlas API
 * The Atlas API allows developers to programmatically access Altana's knowledge graph. This API can be used to search for
entities in the graph and discover relationships between given entities.

# Introduction
Altana's knowledge graph is comprised of nodes and directed edges, all of which have a canonical ID that uniquely identify
the node or edge. Node IDs follow the naming pattern `[node_type]_canon_id` (i.e. `company_canon_id`) and edge IDs follow
the pattern `[edge_type]_id` (i.e. `company_sends_to_id`). All entities have either a `node_type` or an `edge_type` field
that dictates their type (see the `/types` endpoint for all the available types), and all edges have the fields `source_id` and
`destination_id` that contain pointers to the source and destination node, describing the direction of the edge.

# Authentication
Requests are authenticated using an API token that is expected in the `X-Api-Key` header.

# Additional Fields
An important concept to understand is the notion of additional fields. All nodes and edges have a field called
`additional_fields` which is only populated when getting a resource by it's ID, and will be `null` in responses that return
multiple entities. The fields that are documented in the schema of a node or edge are considered core fields and will always
be returned, but the underlying entities in the knowledge graph have more fields associated with them. When `additional_fields`
is populated, it will be a JSON object that contains all other fields (not the fields that are in the schema) and their values
that are stored in the graph for that node or edge.

This is done in order to reduce the size of the payload for requests that return multiple entities.

 * OpenAPI spec version: {{ version or "v0.0.1" }}
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  CatalogFile,
  CatalogFileMetadata,
  CatalogFileResolvedScope,
  Error,
  GetCatalogFileParams,
  ListCatalogFileMetadatasParams,
  ResolveCatalogFileScopingFiltersBodyBody,
  ResolveCatalogFileScopingFiltersParams,
  UpsertCatalogFileBodyBody
} from '.././models'
import { customInstance } from '../../custom-instance';



/**
 * Get a list of metadatas for all saved catalog files
 * @summary List Catalog File Metadatas
 */
export const listCatalogFileMetadatas = (
    params?: ListCatalogFileMetadatasParams,
 signal?: AbortSignal
) => {
      
      
      return customInstance<CatalogFileMetadata[]>(
      {url: `/internal/v3/files/catalog`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getListCatalogFileMetadatasQueryKey = (params?: ListCatalogFileMetadatasParams,) => {
    return [`/internal/v3/files/catalog`, ...(params ? [params]: [])] as const;
    }

    
export const getListCatalogFileMetadatasQueryOptions = <TData = Awaited<ReturnType<typeof listCatalogFileMetadatas>>, TError = Error>(params?: ListCatalogFileMetadatasParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listCatalogFileMetadatas>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListCatalogFileMetadatasQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listCatalogFileMetadatas>>> = ({ signal }) => listCatalogFileMetadatas(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listCatalogFileMetadatas>>, TError, TData> & { queryKey: QueryKey }
}

export type ListCatalogFileMetadatasQueryResult = NonNullable<Awaited<ReturnType<typeof listCatalogFileMetadatas>>>
export type ListCatalogFileMetadatasQueryError = Error

/**
 * @summary List Catalog File Metadatas
 */
export const useListCatalogFileMetadatas = <TData = Awaited<ReturnType<typeof listCatalogFileMetadatas>>, TError = Error>(
 params?: ListCatalogFileMetadatasParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listCatalogFileMetadatas>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListCatalogFileMetadatasQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Create a new catalog file with given values
 * @summary Create Catalog File
 */
export const createCatalogFile = (
    upsertCatalogFileBodyBody: UpsertCatalogFileBodyBody,
 ) => {
      
      
      return customInstance<CatalogFile>(
      {url: `/internal/v3/files/catalog`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: upsertCatalogFileBodyBody
    },
      );
    }
  


export const getCreateCatalogFileMutationOptions = <TError = Error,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createCatalogFile>>, TError,{data: UpsertCatalogFileBodyBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof createCatalogFile>>, TError,{data: UpsertCatalogFileBodyBody}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createCatalogFile>>, {data: UpsertCatalogFileBodyBody}> = (props) => {
          const {data} = props ?? {};

          return  createCatalogFile(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateCatalogFileMutationResult = NonNullable<Awaited<ReturnType<typeof createCatalogFile>>>
    export type CreateCatalogFileMutationBody = UpsertCatalogFileBodyBody
    export type CreateCatalogFileMutationError = Error

    /**
 * @summary Create Catalog File
 */
export const useCreateCatalogFile = <TError = Error,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createCatalogFile>>, TError,{data: UpsertCatalogFileBodyBody}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof createCatalogFile>>,
        TError,
        {data: UpsertCatalogFileBodyBody},
        TContext
      > => {

      const mutationOptions = getCreateCatalogFileMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Resolve the scoping filters of a catalog file
 * @summary Resolve Catalog File Scoping Filters
 */
export const resolveCatalogFileScopingFilters = (
    resolveCatalogFileScopingFiltersBodyBody: ResolveCatalogFileScopingFiltersBodyBody,
    params?: ResolveCatalogFileScopingFiltersParams,
 ) => {
      
      
      return customInstance<CatalogFileResolvedScope>(
      {url: `/internal/v3/files/catalog/resolve_scoping_filters`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: resolveCatalogFileScopingFiltersBodyBody,
        params
    },
      );
    }
  

export const getResolveCatalogFileScopingFiltersQueryKey = (resolveCatalogFileScopingFiltersBodyBody: ResolveCatalogFileScopingFiltersBodyBody,
    params?: ResolveCatalogFileScopingFiltersParams,) => {
    return [`/internal/v3/files/catalog/resolve_scoping_filters`, ...(params ? [params]: []), resolveCatalogFileScopingFiltersBodyBody] as const;
    }

    
export const getResolveCatalogFileScopingFiltersQueryOptions = <TData = Awaited<ReturnType<typeof resolveCatalogFileScopingFilters>>, TError = Error>(resolveCatalogFileScopingFiltersBodyBody: ResolveCatalogFileScopingFiltersBodyBody,
    params?: ResolveCatalogFileScopingFiltersParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof resolveCatalogFileScopingFilters>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getResolveCatalogFileScopingFiltersQueryKey(resolveCatalogFileScopingFiltersBodyBody,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof resolveCatalogFileScopingFilters>>> = () => resolveCatalogFileScopingFilters(resolveCatalogFileScopingFiltersBodyBody,params, );

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof resolveCatalogFileScopingFilters>>, TError, TData> & { queryKey: QueryKey }
}

export type ResolveCatalogFileScopingFiltersQueryResult = NonNullable<Awaited<ReturnType<typeof resolveCatalogFileScopingFilters>>>
export type ResolveCatalogFileScopingFiltersQueryError = Error

/**
 * @summary Resolve Catalog File Scoping Filters
 */
export const useResolveCatalogFileScopingFilters = <TData = Awaited<ReturnType<typeof resolveCatalogFileScopingFilters>>, TError = Error>(
 resolveCatalogFileScopingFiltersBodyBody: ResolveCatalogFileScopingFiltersBodyBody,
    params?: ResolveCatalogFileScopingFiltersParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof resolveCatalogFileScopingFilters>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getResolveCatalogFileScopingFiltersQueryOptions(resolveCatalogFileScopingFiltersBodyBody,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Delete an existing catalog file by file ID
 * @summary Delete Catalog File
 */
export const deleteCatalogFile = (
    fileId: number,
 ) => {
      
      
      return customInstance<void>(
      {url: `/internal/v3/files/catalog/${fileId}`, method: 'DELETE'
    },
      );
    }
  


export const getDeleteCatalogFileMutationOptions = <TError = Error,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteCatalogFile>>, TError,{fileId: number}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof deleteCatalogFile>>, TError,{fileId: number}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteCatalogFile>>, {fileId: number}> = (props) => {
          const {fileId} = props ?? {};

          return  deleteCatalogFile(fileId,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteCatalogFileMutationResult = NonNullable<Awaited<ReturnType<typeof deleteCatalogFile>>>
    
    export type DeleteCatalogFileMutationError = Error

    /**
 * @summary Delete Catalog File
 */
export const useDeleteCatalogFile = <TError = Error,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteCatalogFile>>, TError,{fileId: number}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof deleteCatalogFile>>,
        TError,
        {fileId: number},
        TContext
      > => {

      const mutationOptions = getDeleteCatalogFileMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get a catalog file by id
 * @summary Get Catalog File
 */
export const getCatalogFile = (
    fileId: number,
    params?: GetCatalogFileParams,
 signal?: AbortSignal
) => {
      
      
      return customInstance<CatalogFile>(
      {url: `/internal/v3/files/catalog/${fileId}`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getGetCatalogFileQueryKey = (fileId: number,
    params?: GetCatalogFileParams,) => {
    return [`/internal/v3/files/catalog/${fileId}`, ...(params ? [params]: [])] as const;
    }

    
export const getGetCatalogFileQueryOptions = <TData = Awaited<ReturnType<typeof getCatalogFile>>, TError = Error>(fileId: number,
    params?: GetCatalogFileParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCatalogFile>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCatalogFileQueryKey(fileId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCatalogFile>>> = ({ signal }) => getCatalogFile(fileId,params, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(fileId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCatalogFile>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCatalogFileQueryResult = NonNullable<Awaited<ReturnType<typeof getCatalogFile>>>
export type GetCatalogFileQueryError = Error

/**
 * @summary Get Catalog File
 */
export const useGetCatalogFile = <TData = Awaited<ReturnType<typeof getCatalogFile>>, TError = Error>(
 fileId: number,
    params?: GetCatalogFileParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCatalogFile>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCatalogFileQueryOptions(fileId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Create a new catalog file with given values
 * @summary Update Catalog File
 */
export const updateCatalogFile = (
    fileId: number,
    upsertCatalogFileBodyBody: UpsertCatalogFileBodyBody,
 ) => {
      
      
      return customInstance<CatalogFile>(
      {url: `/internal/v3/files/catalog/${fileId}`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: upsertCatalogFileBodyBody
    },
      );
    }
  


export const getUpdateCatalogFileMutationOptions = <TError = Error,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateCatalogFile>>, TError,{fileId: number;data: UpsertCatalogFileBodyBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof updateCatalogFile>>, TError,{fileId: number;data: UpsertCatalogFileBodyBody}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateCatalogFile>>, {fileId: number;data: UpsertCatalogFileBodyBody}> = (props) => {
          const {fileId,data} = props ?? {};

          return  updateCatalogFile(fileId,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateCatalogFileMutationResult = NonNullable<Awaited<ReturnType<typeof updateCatalogFile>>>
    export type UpdateCatalogFileMutationBody = UpsertCatalogFileBodyBody
    export type UpdateCatalogFileMutationError = Error

    /**
 * @summary Update Catalog File
 */
export const useUpdateCatalogFile = <TError = Error,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateCatalogFile>>, TError,{fileId: number;data: UpsertCatalogFileBodyBody}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof updateCatalogFile>>,
        TError,
        {fileId: number;data: UpsertCatalogFileBodyBody},
        TContext
      > => {

      const mutationOptions = getUpdateCatalogFileMutationOptions(options);

      return useMutation(mutationOptions);
    }
    