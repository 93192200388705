/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Altana Atlas API
 * The Atlas API allows developers to programmatically access Altana's knowledge graph. This API can be used to search for
entities in the graph and discover relationships between given entities.

# Introduction
Altana's knowledge graph is comprised of nodes and directed edges, all of which have a canonical ID that uniquely identify
the node or edge. Node IDs follow the naming pattern `[node_type]_canon_id` (i.e. `company_canon_id`) and edge IDs follow
the pattern `[edge_type]_id` (i.e. `company_sends_to_id`). All entities have either a `node_type` or an `edge_type` field
that dictates their type (see the `/types` endpoint for all the available types), and all edges have the fields `source_id` and
`destination_id` that contain pointers to the source and destination node, describing the direction of the edge.

# Authentication
Requests are authenticated using an API token that is expected in the `X-Api-Key` header.

# Additional Fields
An important concept to understand is the notion of additional fields. All nodes and edges have a field called
`additional_fields` which is only populated when getting a resource by it's ID, and will be `null` in responses that return
multiple entities. The fields that are documented in the schema of a node or edge are considered core fields and will always
be returned, but the underlying entities in the knowledge graph have more fields associated with them. When `additional_fields`
is populated, it will be a JSON object that contains all other fields (not the fields that are in the schema) and their values
that are stored in the graph for that node or edge.

This is done in order to reduce the size of the payload for requests that return multiple entities.

 * OpenAPI spec version: {{ version or "v0.0.1" }}
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  DownscopeRoles,
  Error,
  Exploration,
  ExplorationMetadata,
  ExplorationMetadatas,
  Forbidden,
  ManageExplorationShareActionsRequestBodyBody,
  NewExplorationRequestBodyBody,
  NewExplorationSharingRequestBodyBody,
  NewExplorationVersionRequestBodyBody,
  PatchExplorationRequestBodyBody,
  SharedExplorationMetadatas,
  SharedExplorationState
} from '.././models'
import { customInstance } from '../../custom-instance';



/**
 * @summary List all explorations available to the user.
 */
export const getOwnedExplorationsInternal = (
    
 signal?: AbortSignal
) => {
      
      
      return customInstance<ExplorationMetadatas>(
      {url: `/internal/v3/explorations`, method: 'GET', signal
    },
      );
    }
  

export const getGetOwnedExplorationsInternalQueryKey = () => {
    return [`/internal/v3/explorations`] as const;
    }

    
export const getGetOwnedExplorationsInternalQueryOptions = <TData = Awaited<ReturnType<typeof getOwnedExplorationsInternal>>, TError = Error>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getOwnedExplorationsInternal>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOwnedExplorationsInternalQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOwnedExplorationsInternal>>> = ({ signal }) => getOwnedExplorationsInternal(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getOwnedExplorationsInternal>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOwnedExplorationsInternalQueryResult = NonNullable<Awaited<ReturnType<typeof getOwnedExplorationsInternal>>>
export type GetOwnedExplorationsInternalQueryError = Error

/**
 * @summary List all explorations available to the user.
 */
export const useGetOwnedExplorationsInternal = <TData = Awaited<ReturnType<typeof getOwnedExplorationsInternal>>, TError = Error>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getOwnedExplorationsInternal>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOwnedExplorationsInternalQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Create a new exploration.
 */
export const postNewExplorationInternal = (
    newExplorationRequestBodyBody: NewExplorationRequestBodyBody,
 ) => {
      
      
      return customInstance<ExplorationMetadata>(
      {url: `/internal/v3/explorations`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: newExplorationRequestBodyBody
    },
      );
    }
  


export const getPostNewExplorationInternalMutationOptions = <TError = Error,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postNewExplorationInternal>>, TError,{data: NewExplorationRequestBodyBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postNewExplorationInternal>>, TError,{data: NewExplorationRequestBodyBody}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postNewExplorationInternal>>, {data: NewExplorationRequestBodyBody}> = (props) => {
          const {data} = props ?? {};

          return  postNewExplorationInternal(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostNewExplorationInternalMutationResult = NonNullable<Awaited<ReturnType<typeof postNewExplorationInternal>>>
    export type PostNewExplorationInternalMutationBody = NewExplorationRequestBodyBody
    export type PostNewExplorationInternalMutationError = Error

    /**
 * @summary Create a new exploration.
 */
export const usePostNewExplorationInternal = <TError = Error,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postNewExplorationInternal>>, TError,{data: NewExplorationRequestBodyBody}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postNewExplorationInternal>>,
        TError,
        {data: NewExplorationRequestBodyBody},
        TContext
      > => {

      const mutationOptions = getPostNewExplorationInternalMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary List all shared explorations available to the user.
 */
export const getSharedExplorationsInternal = (
    
 signal?: AbortSignal
) => {
      
      
      return customInstance<SharedExplorationMetadatas>(
      {url: `/internal/v3/explorations/shared_with_me`, method: 'GET', signal
    },
      );
    }
  

export const getGetSharedExplorationsInternalQueryKey = () => {
    return [`/internal/v3/explorations/shared_with_me`] as const;
    }

    
export const getGetSharedExplorationsInternalQueryOptions = <TData = Awaited<ReturnType<typeof getSharedExplorationsInternal>>, TError = Error>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getSharedExplorationsInternal>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSharedExplorationsInternalQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getSharedExplorationsInternal>>> = ({ signal }) => getSharedExplorationsInternal(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getSharedExplorationsInternal>>, TError, TData> & { queryKey: QueryKey }
}

export type GetSharedExplorationsInternalQueryResult = NonNullable<Awaited<ReturnType<typeof getSharedExplorationsInternal>>>
export type GetSharedExplorationsInternalQueryError = Error

/**
 * @summary List all shared explorations available to the user.
 */
export const useGetSharedExplorationsInternal = <TData = Awaited<ReturnType<typeof getSharedExplorationsInternal>>, TError = Error>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getSharedExplorationsInternal>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetSharedExplorationsInternalQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Delete an exploration and all it's versions.
 */
export const deleteExplorationInternal = (
    id: number,
 ) => {
      
      
      return customInstance<void>(
      {url: `/internal/v3/explorations/${id}`, method: 'DELETE'
    },
      );
    }
  


export const getDeleteExplorationInternalMutationOptions = <TError = Error,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteExplorationInternal>>, TError,{id: number}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof deleteExplorationInternal>>, TError,{id: number}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteExplorationInternal>>, {id: number}> = (props) => {
          const {id} = props ?? {};

          return  deleteExplorationInternal(id,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteExplorationInternalMutationResult = NonNullable<Awaited<ReturnType<typeof deleteExplorationInternal>>>
    
    export type DeleteExplorationInternalMutationError = Error

    /**
 * @summary Delete an exploration and all it's versions.
 */
export const useDeleteExplorationInternal = <TError = Error,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteExplorationInternal>>, TError,{id: number}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof deleteExplorationInternal>>,
        TError,
        {id: number},
        TContext
      > => {

      const mutationOptions = getDeleteExplorationInternalMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Update an exploration.
 */
export const patchExplorationInternal = (
    id: number,
    patchExplorationRequestBodyBody: PatchExplorationRequestBodyBody,
 ) => {
      
      
      return customInstance<void>(
      {url: `/internal/v3/explorations/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: patchExplorationRequestBodyBody
    },
      );
    }
  


export const getPatchExplorationInternalMutationOptions = <TError = Error,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchExplorationInternal>>, TError,{id: number;data: PatchExplorationRequestBodyBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof patchExplorationInternal>>, TError,{id: number;data: PatchExplorationRequestBodyBody}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchExplorationInternal>>, {id: number;data: PatchExplorationRequestBodyBody}> = (props) => {
          const {id,data} = props ?? {};

          return  patchExplorationInternal(id,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchExplorationInternalMutationResult = NonNullable<Awaited<ReturnType<typeof patchExplorationInternal>>>
    export type PatchExplorationInternalMutationBody = PatchExplorationRequestBodyBody
    export type PatchExplorationInternalMutationError = Error

    /**
 * @summary Update an exploration.
 */
export const usePatchExplorationInternal = <TError = Error,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchExplorationInternal>>, TError,{id: number;data: PatchExplorationRequestBodyBody}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof patchExplorationInternal>>,
        TError,
        {id: number;data: PatchExplorationRequestBodyBody},
        TContext
      > => {

      const mutationOptions = getPatchExplorationInternalMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Save a new version of an exploration.
 */
export const postNewExplorationVersionInternal = (
    id: number,
    newExplorationVersionRequestBodyBody: NewExplorationVersionRequestBodyBody,
 ) => {
      
      
      return customInstance<void>(
      {url: `/internal/v3/explorations/${id}`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: newExplorationVersionRequestBodyBody
    },
      );
    }
  


export const getPostNewExplorationVersionInternalMutationOptions = <TError = Error,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postNewExplorationVersionInternal>>, TError,{id: number;data: NewExplorationVersionRequestBodyBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postNewExplorationVersionInternal>>, TError,{id: number;data: NewExplorationVersionRequestBodyBody}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postNewExplorationVersionInternal>>, {id: number;data: NewExplorationVersionRequestBodyBody}> = (props) => {
          const {id,data} = props ?? {};

          return  postNewExplorationVersionInternal(id,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostNewExplorationVersionInternalMutationResult = NonNullable<Awaited<ReturnType<typeof postNewExplorationVersionInternal>>>
    export type PostNewExplorationVersionInternalMutationBody = NewExplorationVersionRequestBodyBody
    export type PostNewExplorationVersionInternalMutationError = Error

    /**
 * @summary Save a new version of an exploration.
 */
export const usePostNewExplorationVersionInternal = <TError = Error,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postNewExplorationVersionInternal>>, TError,{id: number;data: NewExplorationVersionRequestBodyBody}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postNewExplorationVersionInternal>>,
        TError,
        {id: number;data: NewExplorationVersionRequestBodyBody},
        TContext
      > => {

      const mutationOptions = getPostNewExplorationVersionInternalMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Get the sharing state for the exploration including owner information, user-level access, and org-level access.
 */
export const getManageShareActionsInternal = (
    id: number,
 signal?: AbortSignal
) => {
      
      
      return customInstance<SharedExplorationState>(
      {url: `/internal/v3/explorations/${id}/manage`, method: 'GET', signal
    },
      );
    }
  

export const getGetManageShareActionsInternalQueryKey = (id: number,) => {
    return [`/internal/v3/explorations/${id}/manage`] as const;
    }

    
export const getGetManageShareActionsInternalQueryOptions = <TData = Awaited<ReturnType<typeof getManageShareActionsInternal>>, TError = void | Error>(id: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getManageShareActionsInternal>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetManageShareActionsInternalQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getManageShareActionsInternal>>> = ({ signal }) => getManageShareActionsInternal(id, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getManageShareActionsInternal>>, TError, TData> & { queryKey: QueryKey }
}

export type GetManageShareActionsInternalQueryResult = NonNullable<Awaited<ReturnType<typeof getManageShareActionsInternal>>>
export type GetManageShareActionsInternalQueryError = void | Error

/**
 * @summary Get the sharing state for the exploration including owner information, user-level access, and org-level access.
 */
export const useGetManageShareActionsInternal = <TData = Awaited<ReturnType<typeof getManageShareActionsInternal>>, TError = void | Error>(
 id: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getManageShareActionsInternal>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetManageShareActionsInternalQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Share/unshare an exploration with one or more users or with all users in the organization.
 */
export const postManageShareActionsInternal = (
    id: number,
    manageExplorationShareActionsRequestBodyBody: ManageExplorationShareActionsRequestBodyBody,
 ) => {
      
      
      return customInstance<SharedExplorationState>(
      {url: `/internal/v3/explorations/${id}/manage`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: manageExplorationShareActionsRequestBodyBody
    },
      );
    }
  


export const getPostManageShareActionsInternalMutationOptions = <TError = void | Error,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postManageShareActionsInternal>>, TError,{id: number;data: ManageExplorationShareActionsRequestBodyBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postManageShareActionsInternal>>, TError,{id: number;data: ManageExplorationShareActionsRequestBodyBody}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postManageShareActionsInternal>>, {id: number;data: ManageExplorationShareActionsRequestBodyBody}> = (props) => {
          const {id,data} = props ?? {};

          return  postManageShareActionsInternal(id,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostManageShareActionsInternalMutationResult = NonNullable<Awaited<ReturnType<typeof postManageShareActionsInternal>>>
    export type PostManageShareActionsInternalMutationBody = ManageExplorationShareActionsRequestBodyBody
    export type PostManageShareActionsInternalMutationError = void | Error

    /**
 * @summary Share/unshare an exploration with one or more users or with all users in the organization.
 */
export const usePostManageShareActionsInternal = <TError = void | Error,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postManageShareActionsInternal>>, TError,{id: number;data: ManageExplorationShareActionsRequestBodyBody}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postManageShareActionsInternal>>,
        TError,
        {id: number;data: ManageExplorationShareActionsRequestBodyBody},
        TContext
      > => {

      const mutationOptions = getPostManageShareActionsInternalMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Get the roles that the user must downscope to to see the exploration at the appropriate data access level.
 */
export const getExplorationRolesToDownscopeToInternal = (
    id: number,
 signal?: AbortSignal
) => {
      
      
      return customInstance<DownscopeRoles[]>(
      {url: `/internal/v3/explorations/${id}/roles_to_downscope_to`, method: 'GET', signal
    },
      );
    }
  

export const getGetExplorationRolesToDownscopeToInternalQueryKey = (id: number,) => {
    return [`/internal/v3/explorations/${id}/roles_to_downscope_to`] as const;
    }

    
export const getGetExplorationRolesToDownscopeToInternalQueryOptions = <TData = Awaited<ReturnType<typeof getExplorationRolesToDownscopeToInternal>>, TError = Error>(id: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getExplorationRolesToDownscopeToInternal>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetExplorationRolesToDownscopeToInternalQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getExplorationRolesToDownscopeToInternal>>> = ({ signal }) => getExplorationRolesToDownscopeToInternal(id, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getExplorationRolesToDownscopeToInternal>>, TError, TData> & { queryKey: QueryKey }
}

export type GetExplorationRolesToDownscopeToInternalQueryResult = NonNullable<Awaited<ReturnType<typeof getExplorationRolesToDownscopeToInternal>>>
export type GetExplorationRolesToDownscopeToInternalQueryError = Error

/**
 * @summary Get the roles that the user must downscope to to see the exploration at the appropriate data access level.
 */
export const useGetExplorationRolesToDownscopeToInternal = <TData = Awaited<ReturnType<typeof getExplorationRolesToDownscopeToInternal>>, TError = Error>(
 id: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getExplorationRolesToDownscopeToInternal>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetExplorationRolesToDownscopeToInternalQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Requests an exploration to be shared
 */
export const postExplorationSharingRequest = (
    id: number,
    newExplorationSharingRequestBodyBody: NewExplorationSharingRequestBodyBody,
 ) => {
      
      
      return customInstance<void>(
      {url: `/internal/v3/explorations/${id}/sharing_request`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: newExplorationSharingRequestBodyBody
    },
      );
    }
  


export const getPostExplorationSharingRequestMutationOptions = <TError = Error | Forbidden,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postExplorationSharingRequest>>, TError,{id: number;data: NewExplorationSharingRequestBodyBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postExplorationSharingRequest>>, TError,{id: number;data: NewExplorationSharingRequestBodyBody}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postExplorationSharingRequest>>, {id: number;data: NewExplorationSharingRequestBodyBody}> = (props) => {
          const {id,data} = props ?? {};

          return  postExplorationSharingRequest(id,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostExplorationSharingRequestMutationResult = NonNullable<Awaited<ReturnType<typeof postExplorationSharingRequest>>>
    export type PostExplorationSharingRequestMutationBody = NewExplorationSharingRequestBodyBody
    export type PostExplorationSharingRequestMutationError = Error | Forbidden

    /**
 * @summary Requests an exploration to be shared
 */
export const usePostExplorationSharingRequest = <TError = Error | Forbidden,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postExplorationSharingRequest>>, TError,{id: number;data: NewExplorationSharingRequestBodyBody}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postExplorationSharingRequest>>,
        TError,
        {id: number;data: NewExplorationSharingRequestBodyBody},
        TContext
      > => {

      const mutationOptions = getPostExplorationSharingRequestMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary List all versions of an exploration.
 */
export const getExplorationVersionsInternal = (
    id: number,
 signal?: AbortSignal
) => {
      
      
      return customInstance<ExplorationMetadata>(
      {url: `/internal/v3/explorations/${id}/versions`, method: 'GET', signal
    },
      );
    }
  

export const getGetExplorationVersionsInternalQueryKey = (id: number,) => {
    return [`/internal/v3/explorations/${id}/versions`] as const;
    }

    
export const getGetExplorationVersionsInternalQueryOptions = <TData = Awaited<ReturnType<typeof getExplorationVersionsInternal>>, TError = Error>(id: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getExplorationVersionsInternal>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetExplorationVersionsInternalQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getExplorationVersionsInternal>>> = ({ signal }) => getExplorationVersionsInternal(id, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getExplorationVersionsInternal>>, TError, TData> & { queryKey: QueryKey }
}

export type GetExplorationVersionsInternalQueryResult = NonNullable<Awaited<ReturnType<typeof getExplorationVersionsInternal>>>
export type GetExplorationVersionsInternalQueryError = Error

/**
 * @summary List all versions of an exploration.
 */
export const useGetExplorationVersionsInternal = <TData = Awaited<ReturnType<typeof getExplorationVersionsInternal>>, TError = Error>(
 id: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getExplorationVersionsInternal>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetExplorationVersionsInternalQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Get an exploration.
 */
export const getExplorationByIdAndVersionInternal = (
    id: number,
    version: string,
 signal?: AbortSignal
) => {
      
      
      return customInstance<Exploration>(
      {url: `/internal/v3/explorations/${id}/versions/${version}`, method: 'GET', signal
    },
      );
    }
  

export const getGetExplorationByIdAndVersionInternalQueryKey = (id: number,
    version: string,) => {
    return [`/internal/v3/explorations/${id}/versions/${version}`] as const;
    }

    
export const getGetExplorationByIdAndVersionInternalQueryOptions = <TData = Awaited<ReturnType<typeof getExplorationByIdAndVersionInternal>>, TError = Error | Forbidden>(id: number,
    version: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getExplorationByIdAndVersionInternal>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetExplorationByIdAndVersionInternalQueryKey(id,version);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getExplorationByIdAndVersionInternal>>> = ({ signal }) => getExplorationByIdAndVersionInternal(id,version, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id && version), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getExplorationByIdAndVersionInternal>>, TError, TData> & { queryKey: QueryKey }
}

export type GetExplorationByIdAndVersionInternalQueryResult = NonNullable<Awaited<ReturnType<typeof getExplorationByIdAndVersionInternal>>>
export type GetExplorationByIdAndVersionInternalQueryError = Error | Forbidden

/**
 * @summary Get an exploration.
 */
export const useGetExplorationByIdAndVersionInternal = <TData = Awaited<ReturnType<typeof getExplorationByIdAndVersionInternal>>, TError = Error | Forbidden>(
 id: number,
    version: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getExplorationByIdAndVersionInternal>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetExplorationByIdAndVersionInternalQueryOptions(id,version,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



